import { ClusterOutlined } from "@ant-design/icons";

import {
  BritainFlagIcon,
  ChinaFlagIcon,
  FranceFlagIcon,
  GermanyFlagIcon,
  HomeIcon,
  SiteIcon,
  AttachmentIcon,
  DeviceIcon,
  AlarmIcon,
  UserIcon,
  UsersIcon,
  LicenseIcon,
  TelemetryIcon,
  ProfileIcon,
} from "@datwyler/mfe-shared-components";

export const pageList = [
  "home",
  "site",
  "attachments",
  "device-monitoring",
  "alarm",
  "user",
  "tenant",
  "license",
  "telemetry",
  "gateway",
  "profile",
];

export const pageListSB = ["building", "profile"];

export const getNavBarIcon = (item) => {
  const width = "24px";
  const height = "24px";

  switch (item) {
    case "home":
      return <HomeIcon width={width} height={height} />;
    case "site":
      return <SiteIcon width={width} height={height} />;
    case "device-monitoring":
      return <DeviceIcon width={width} height={height} />;
    case "alarm":
      return <AlarmIcon width={width} height={height} />;
    case "user":
      return <UserIcon width={width} height={height} />;
    case "tenant":
      return <UsersIcon width={width} height={height} />;
    case "license":
      return <LicenseIcon width={width} height={height} />;
    case "profile":
      return <ProfileIcon width={width} height={height} />;
    case "telemetry":
      return <TelemetryIcon width={width} height={height} />;
    case "attachments":
      return <AttachmentIcon width={width} height={height} />;
    case "gateway":
      return (
        <ClusterOutlined style={{ color: "currentColor", fontSize: "24px" }} />
      );
    default:
      return null;
  }
};

export const getFlagIcon = (l, commonStyle) => {
  const width = "24px";
  const height = "24px";

  switch (l) {
    case "en-GB":
      return (
        <BritainFlagIcon width={width} height={height} style={commonStyle} />
      );
    case "zh-CN":
      return (
        <ChinaFlagIcon width={width} height={height} style={commonStyle} />
      );
    case "fr-FR":
      return (
        <FranceFlagIcon width={width} height={height} style={commonStyle} />
      );
    case "de-CH":
      return (
        <GermanyFlagIcon width={width} height={height} style={commonStyle} />
      );
    default:
      return (
        <BritainFlagIcon width={width} height={height} style={commonStyle} />
      );
  }
};
