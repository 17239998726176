import { useContext, useEffect, useState } from "react";
import {
  type MenuProps,
  Menu,
  Layout,
  Space,
  Row,
  Col,
  Button,
  Typography,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { pageList, getNavBarIcon } from "../../utils";
import { sideMenuLabelStyle } from "../../utils/styles";
import MasqSubTenantSelect from "../HeaderBar/MasqSubTenantSelect";
import {
  CollapseIcon,
  CloseIcon,
  DatwylerLogoDark,
  DatwylerLogo,
  Overlay,
  DarkModeContext,
  hasAccess,
  HEADERHEIGHTMOBILE,
  HEADERHEIGHT,
  COLLAPSEDNAVBARWIDTH,
  styled,
} from "@datwyler/mfe-shared-components";

const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];
const { Text } = Typography;

const StyledSider = styled(Sider)`
  .ant-menu-item:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ):hover {
    color: #0c8595 !important;
  }
`;

const getPermissions = (role) => {
  return pageList.filter((page) => hasAccess(role, page));
};

const SideBar = (props: any) => {
  const {
    user = {},
    isMobileScreen,
    collapsed,
    setCollapsed,
    triggerNavBarWidth,
    module,
  } = props;
  const [activePage, setActivePage] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();
  const { isDarkMode }: any = useContext(DarkModeContext);
  const intl = useIntl();

  const siderStyle: React.CSSProperties = {
    textAlign: isMobileScreen ? "left" : "center",
    lineHeight: isMobileScreen ? "0px" : "120px",
    backgroundColor: isDarkMode
      ? isMobileScreen
        ? "#191A24"
        : "#12141C"
      : "#f8f8fa",
    position: "absolute",
    zIndex: isMobileScreen ? 1002 : 0,
    minHeight: `calc(100vh - ${
      isMobileScreen ? HEADERHEIGHTMOBILE : HEADERHEIGHT
    }px)`,
    top: isMobileScreen ? "0px" : "unset",
  };

  const allowedPages = getPermissions(user.role);

  const menuItems: MenuItem[] = allowedPages.map((page) => {
    return {
      key: page,
      label: collapsed ? "" : intl.formatMessage({ id: "menu_" + page }),
      icon: getNavBarIcon(page),
      style: {
        ...sideMenuLabelStyle,
        fontWeight: page === activePage ? 600 : 400,
        marginBottom: "12px",
        padding: "12px",
        paddingLeft: "12px",
        paddingTop: collapsed ? "10px" : "12px",
        textAlign: "left",
        gap: "12px",
        width: isMobileScreen
          ? "100%"
          : collapsed
          ? "48px"
          : COLLAPSEDNAVBARWIDTH + "px",
        height: "48px",
        borderRadius: collapsed ? "24px" : "8px",
      },
      title: "",
    };
  });

  menuItems.push({
    key: "",
    icon: (
      <CollapseIcon
        width={"24px"}
        height={"24px"}
        style={{ rotate: collapsed ? "180deg" : "0deg" }}
      />
    ),
    style: {
      marginLeft: collapsed ? "4px" : "auto",
      marginBottom: "12px",
      padding: "12px",
      paddingLeft: "12px",
      textAlign: "left",
      width: "48px",
      height: "48px",
      borderRadius: "24px",
    },
  });

  useEffect(() => {
    if (location && allowedPages.length > 0) {
      setActivePage(getDefaultPage());
    }
  }, [location, allowedPages]);

  const getDefaultPage = () => {
    if (location.pathname === "/user/profile") return "profile";
    else if (location.pathname === "/tenant/license") return "license";
    else if (location.pathname === "/site/attachments") return "attachments";
    // take the first string AFTER '/'
    const path = location.pathname.split("/")[1];
    if (allowedPages.includes(path)) {
      return path;
    }
    return "home";
  };

  const getLocationState = () => {
    const state = { ...location.state, tenantId: null };
    if (location?.state?.tenantId) {
      // if state already has a tenantId eg. sub tenant id
      state.tenantId = location.state.tenantId;
    } else if (user?.tenant?.id) {
      // if on first load no tenantId in state
      state.tenantId = user.tenant.id;
    }
    return state;
  };

  const handleMenuClick = (page) => {
    if (page === "") {
      triggerNavBarWidth(!collapsed);
      setCollapsed(!collapsed);
      window.localStorage.setItem("isNavBarCollapsed", (!collapsed).toString());
      return;
    }

    if (isMobileScreen) {
      setTimeout(() => setCollapsed(true), 500);
    }
    const state = getLocationState();

    setActivePage(page);
    let newPage = "";
    //if (module === "smart-building") newPage = "/smart-building";

    if (page === "user") {
      newPage += "/user";
    } else if (page === "profile") {
      newPage += "/user/profile";
    } else if (page === "license") {
      newPage += "/tenant/license";
    } else if (page === "attachments") {
      newPage += "/site/attachments";
    }
    navigate(newPage || page, { state: state });
  };

  const SideBarHeader = () => {
    return (
      <Row style={{ alignItems: "center" }}>
        <Col
          span={22}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.assign("/");
          }}
        >
          {isDarkMode ? (
            <DatwylerLogoDark height={"36"} width={"216"} />
          ) : (
            <DatwylerLogo height={"36"} width={"216"} />
          )}
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Button
            onClick={() => setCollapsed(!collapsed)}
            type="text"
            icon={<CloseIcon width={"24px"} height={"24px"} />}
          />
        </Col>
      </Row>
    );
  };

  const handleOnSelectSite = () => {
    setCollapsed(true);
  };

  return (
    <Layout>
      <StyledSider
        width={"100%"}
        style={siderStyle}
        collapsible
        collapsed={collapsed}
        trigger={null}
        breakpoint="lg"
        collapsedWidth={isMobileScreen ? "0px" : COLLAPSEDNAVBARWIDTH + "px"}
      >
        {isMobileScreen && !collapsed && (
          <Overlay backgroundColor={isDarkMode ? "#191A24" : "#FFFFFF"} />
        )}
        <Space
          style={{
            display: "flex",
            padding: isMobileScreen ? "24px 12px 24px 12px" : "32px",
            gap: "0px",
          }}
          direction="vertical"
          size="middle"
        >
          {isMobileScreen && <SideBarHeader />}
          <Menu
            onClick={(e) => handleMenuClick(e.key)}
            style={{
              justifyContent: "space-between",
              border: isMobileScreen
                ? "none"
                : isDarkMode
                ? "1px solid #5A5C5F"
                : "1px solid #E1E1E4",
              borderRadius: "8px",
              width: isMobileScreen ? "100%" : collapsed ? "96px" : "216px",
              padding: isMobileScreen
                ? "24px 0px 12px"
                : collapsed
                ? "40px 16px 24px 16px"
                : "40px 28px 24px 28px",
              backgroundColor: isDarkMode ? "unset" : "white",
            }}
            selectedKeys={[activePage]}
            mode="inline"
            items={menuItems}
          />
          {isMobileScreen && (
            <Row>
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{
                    color: isDarkMode ? "#FFFFFF" : "#12141C",
                    paddingLeft: "8px",
                  }}
                >
                  <Text style={{ color: "#83878E" }}>
                    {intl.formatMessage({ id: "select_tenant" })}
                  </Text>
                  <Space style={{ lineHeight: "20px" }}>
                    <MasqSubTenantSelect
                      user={user}
                      handleOnSelect={handleOnSelectSite}
                    />
                  </Space>
                </Space>
              </Col>
            </Row>
          )}
        </Space>
      </StyledSider>
    </Layout>
  );
};

export default SideBar;
