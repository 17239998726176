import { useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderBar from "../component/HeaderBar";
import SideBar from "../component/SideBar";
import {
  useScreenOptions,
  DEFAULTNAVBARWIDTH,
  UserContext,
} from "@datwyler/mfe-shared-components";

const layoutStyle = {
  overflow: "hidden",
};

const getDefaultIsCollapsed = () => {
  return window.localStorage.getItem("isNavBarCollapsed") === "true";
};

const NavBar = (props: any) => {
  const { locale, version, module } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { user }: any = useContext(UserContext);
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(
    getDefaultIsCollapsed()
  );

  const { isMobileScreen } = useScreenOptions();

  const triggerNavBarWidth = (collapsed) => {
    let newNavBarWidth;

    if (isMobileScreen) {
      newNavBarWidth = "0";
    } else if (!collapsed) {
      newNavBarWidth = DEFAULTNAVBARWIDTH.toString();
    } else {
      newNavBarWidth = "160";
    }

    window.localStorage.setItem("navBarWidth", newNavBarWidth);
    window.dispatchEvent(new Event("navBarChanged"));
  };

  useEffect(() => {
    let collapsed = false;
    if (isMobileScreen) {
      collapsed = true;
    } else {
      collapsed = getDefaultIsCollapsed();
    }

    setIsSideBarCollapsed(collapsed);
    triggerNavBarWidth(collapsed);
  }, [isMobileScreen]);

  const getLocationState = () => {
    const state = { ...location.state, tenantId: null };
    if (location?.state?.tenantId) {
      // if state already has a tenantId eg. sub tenant id
      state.tenantId = location.state.tenantId;
    } else if (user?.tenant?.id) {
      // if on first load no tenantId in state
      state.tenantId = user.tenant.id;
    }

    return state;
  };

  useEffect(() => {
    if (user?.tenant?.id && location?.pathname && !location?.state?.tenantId) {
      navigate(location.pathname, { state: getLocationState() });
    }
  }, [user, location]);

  return (
    <Layout style={layoutStyle}>
      <HeaderBar
        user={user}
        locale={locale}
        collapsed={isSideBarCollapsed}
        setCollapsed={setIsSideBarCollapsed}
        isMobileScreen={isMobileScreen}
      />
      <SideBar
        user={user}
        collapsed={isSideBarCollapsed}
        setCollapsed={setIsSideBarCollapsed}
        triggerNavBarWidth={triggerNavBarWidth}
        isMobileScreen={isMobileScreen}
        module={module}
      />
    </Layout>
  );
};

export default NavBar;
