import { Route, Routes } from "react-router-dom";
import NavBar from "./containers/NavBar";
import MockModuleSelect from "./containers/MockModuleSelect";
import { DarkModeContext } from "@datwyler/mfe-shared-components";
import { ConfigProvider } from "antd";
import { useContext } from "react";

const Router = (props: any) => {
  const { locale, version, module } = props;

  const { theme } = useContext<any>(DarkModeContext);

  return (
    <Routes>
      <Route path="/" element={<MockModuleSelect />} />
      <Route
        path="/*"
        element={
          <ConfigProvider theme={theme}>
            <NavBar locale={locale} version={version} module={module} />
          </ConfigProvider>
        }
      />
    </Routes>
  );
};

export default Router;
