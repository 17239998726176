import { useEffect, useState } from "react";
import { MenuProps } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  dropdownMenuItemStyle,
  fontStyle,
  siteNameStyle,
} from "../../utils/styles";
import {
  ACTIVE,
  useTenant,
  useSubTenant,
  BasicDropdown,
} from "@datwyler/mfe-shared-components";

const MasqSubTenantSelect = (props) => {
  const { user, handleOnSelect } = props;
  const { fetchTenantById, fetchTenantByIdData, isfetchTenantByIdLoading } =
    useTenant();
  const { fetchSubTenant, fetchSubTenantData, isfetchSubTenantLoading } =
    useSubTenant();
  const location = useLocation();
  const navigate = useNavigate();
  const [subTenantOptions, setSubTenantOptions] = useState<MenuProps["items"]>(
    []
  );

  const findTenantName = (id) => {
    if (fetchTenantByIdData?.tenantById?.id === id)
      return fetchTenantByIdData?.tenantById?.name;
    else {
      return (
        fetchSubTenantData?.subTenants?.tenants?.find((subTenant) => {
          return subTenant.id === id;
        })?.name || ""
      );
    }
  };

  const [selectedSubTenant, setSelectedSubTenant] = useState<String>("");

  useEffect(() => {
    fetchSubTenants(user);
    fetchTenant(user);
  }, [user]);

  useEffect(() => {
    if (fetchTenantByIdData?.tenantById?.id) {
      const style = {
        ...fontStyle,
        ...dropdownMenuItemStyle,
      };
      const subTenantOptions = [
        {
          key: fetchTenantByIdData.tenantById.id,
          label: fetchTenantByIdData.tenantById.name,
          style: {
            ...style,
          },
        },
      ];

      if (fetchSubTenantData?.subTenants?.tenants) {
        // populate the drop down
        const subTenants = fetchSubTenantData?.subTenants?.tenants;

        subTenants.forEach((subTenant) => {
          if (subTenant.status === ACTIVE && subTenant.managed) {
            subTenantOptions.push({
              key: subTenant.id,
              label: subTenant.name,
              style: {
                ...style,
              },
            });
          }
        });
      }

      setSubTenantOptions(subTenantOptions);
      // set main tenant as default
      if (!selectedSubTenant) {
        if (location?.state?.tenantId)
          setSelectedSubTenant(findTenantName(location?.state?.tenantId));
        else setSelectedSubTenant(fetchTenantByIdData.tenantById.name);
      }
    }
  }, [fetchSubTenantData, fetchTenantByIdData, location]);

  window.addEventListener("tenantsChanged", () => {
    fetchSubTenants(user);
    fetchTenant(user);
  });

  const fetchSubTenants = (user) => {
    if (user?.tenant?.id) {
      fetchSubTenant({
        variables: {
          tenantId: user.tenant.id,
          page: { number: 0, size: 999999 },
          filter: [`status:${ACTIVE}`],
          sort: [`name,asc`],
        },
      });
    }
  };

  const fetchTenant = (user) => {
    if (user?.tenant?.id) {
      fetchTenantById({
        variables: {
          id: user.tenant.id,
        },
      });
    }
  };

  const handleChangeMasqTenant = (e) => {
    const selectedTenantName = findTenantName(e.key);
    setSelectedSubTenant(selectedTenantName);
    const state = {
      ...location.state,
      tenantId: e.key,
      isMasqAsSubTenant: e.key !== fetchTenantByIdData.tenantById.id,
    };
    if (handleOnSelect) handleOnSelect();
    navigate(location.pathname, { state: state });
  };

  return (
    <BasicDropdown
      onClick={handleChangeMasqTenant}
      items={subTenantOptions}
      labelStyle={siteNameStyle}
      label={selectedSubTenant}
      isSpinning={isfetchSubTenantLoading || isfetchTenantByIdLoading || false}
    />
  );
};

export default MasqSubTenantSelect;
